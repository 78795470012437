import * as React from 'react';
import type { Region, Action } from './SupportBrowserRegionPage_service';

type Props = {
  dispatch: React.Dispatch<Action>,
  searchResults: Region[],
  selection: Record<string, boolean>,
};

const SupportBrowseRegionPage_SearchResult: React.FC<Props> = ({
  dispatch,
  searchResults,
  selection,
}) => {
  const hasSearchResults = searchResults.length > 0;
  const allChecked = searchResults.every(result => selection[result.publicId]);
  return (
    <div>
      검색 결과 ({searchResults.length})
      {hasSearchResults && (
        <label>
          <input
            type="checkbox"
            checked={allChecked}
            onChange={_e => dispatch({
              type: allChecked ? 'UNSELECT_ALL' : 'SELECT_ALL',
              ids: searchResults.map(result => result.publicId),
            })}
          />
          전체 선택
        </label>
      )}
      <ul>
        {searchResults.map(({ fullName, publicId }) => (
          <li key={publicId}>
            <label>
              <input
                type="checkbox"
                checked={selection[publicId] || false}
                onChange={_e => dispatch({ type: 'TOGGLE', id: publicId })}
              />
              {fullName} ({publicId})
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(SupportBrowseRegionPage_SearchResult);
