/// <reference types="react/experimental" />

import * as React from 'react';
import type { PageProps } from 'gatsby';
import { graphql, navigate } from 'gatsby';
import { useImmerReducer } from 'use-immer';
import type { Callable } from '@cometjs/core';
import { required } from '@cometjs/core';

import Layout from '../components/Layout';
import SearchResult from './SupportBrowseRegionPage_SearchResult';
import SelectedRegions from './SupportBrowseRegionPage_SelectedRegions';
import type { Region } from './SupportBrowserRegionPage_service';
import { reducer } from './SupportBrowserRegionPage_service';

type Props = PageProps<GatsbyTypes.SupportBrowseRegionPageQuery>;

export const query = graphql`
  query SupportBrowseRegionPage {
    localSearchRegions {
      index
      store
    }
  }
`;

const SupportBrowseRegionPage: React.FC<Props> = ({
  data: {
    localSearchRegions,
  },
}) => {
  required(localSearchRegions);

  const { index, store } = localSearchRegions;
  const [query, setQuery] = React.useState<string>('');
  const deferredQuery = React.useDeferredValue(query);
  const searchResults = useFlexSearch<Region>(
    deferredQuery,
    index,
    store,
  );

  const [state, dispatch] = useImmerReducer(reducer, {
    store,
    init: null,
  });

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const idsParam = searchParams.get('ids') || '';
    const ids = idsParam.split(',').filter(Boolean);
    dispatch({ type: 'INIT', ids });
  }, []);

  const searchParams = useURLSearchParams();
  React.useEffect(() => {
    if (!state.init) {
      return;
    }
    const currentIdsParam = searchParams.get('ids') || '';
    const idsParam = state.init.selectedRegionIds.join(',');
    if (currentIdsParam !== idsParam) {
      navigate('?ids=' + idsParam, { replace: true });
    }
  }, [searchParams, state.init?.selectedRegionIds]);

  return (
    <Layout>
      <header>
        <h1>노출 지역 검색</h1>
      </header>
      <main>
        <div>
          <form onSubmit={e => e.preventDefault()}>
            <label>
              지역명:
              <input
                autoFocus
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </label>
          </form>
        </div>

        {state.init && (
          <div style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          }}>
            <SearchResult
              dispatch={dispatch}
              searchResults={searchResults}
              selection={state.init.selection}
            />
            <SelectedRegions
              store={store}
              dispatch={dispatch}
              selectedRegionIds={state.init.selectedRegionIds}
              onChangeQuery={setQuery}
            />
          </div>
        )}
      </main>
    </Layout>
  );
};

export default SupportBrowseRegionPage;

function useURLSearchParams(): URLSearchParams {
  const [
    getSnapshot,
    getServerSnapshot,
    subscribe,
  ] = React.useMemo(() => {
    return [
      () => window.location.search,
      () => '',
      (notify: Callable) => {
        window.addEventListener('hashchange', notify);
        return () => {
          window.removeEventListener('hashchange', notify);
        };
      },
    ] as const;
  }, []);

  const search = React.useSyncExternalStore(
    subscribe,
    getSnapshot,
    getServerSnapshot,
  );

  return React.useMemo(
    () => new URLSearchParams(search),
    [search],
  );
}

function useFlexSearch<T>(query: string, indexRaw: string, store: Record<string, T>): T[] {
  const [index] = React.useMemo<any[]>(() => JSON.parse(indexRaw), [indexRaw]);
  const [searchResult, setSearchResult] = React.useState<T[]>(() => []);

  React.useEffect(() => {
    if (query) {
      let result: string[] = [];
      for (const i of index) {
        const ids = i[query];
        if (ids) {
          result = result.concat(ids);
        }
      }
      setSearchResult(result.map(id => store[id]));
    } else {
      setSearchResult([]);
    }
  }, [index, query]);

  return searchResult;
}
