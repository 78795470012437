import type { Reducer } from 'use-immer';

export type Region = {
  slug: string,
  publicId: string,
  fullName: string,
};

export type State = {
  store: Record<string, Region>,
  init: null | {
    selection: Record<string, boolean>,
    selectedRegionIds: string[],
  },
};

export type Action = (
  | { type: 'INIT', ids: string[] }
  | { type: 'TOGGLE', id: string }
  | { type: 'SELECT', id: string }
  | { type: 'UNSELECT', id: string }
  | { type: 'SELECT_ALL', ids: string[] }
  | { type: 'UNSELECT_ALL', ids: string[] }
);

export const reducer: Reducer<State, Action> = (draft, action) => {
  if (!draft.init) {
    switch (action.type) {
      case 'INIT':
        draft.init = {
          selectedRegionIds: action.ids,
          selection: Object.fromEntries(action.ids.map(id => [id, true])),
        };
        return;
    }
    return;
  }
  switch (action.type) {
    case 'TOGGLE': {
      draft.init.selection[action.id] = !draft.init.selection[action.id];
      break;
    }
    case 'SELECT': {
      draft.init.selection[action.id] = true;
      break;
    }
    case 'UNSELECT': {
      draft.init.selection[action.id] = false;
      break;
    }
    case 'SELECT_ALL': {
      for (const id of action.ids) {
        draft.init.selection[id] = true;
      }
      break;
    }
    case 'UNSELECT_ALL': {
      for (const id of action.ids) {
        draft.init.selection[id] = false;
      }
      break;
    }
  }

  const selectedRegionIds: string[] = [];
  for (const [key, value] of Object.entries(draft.init.selection)) {
    if (value) {
      selectedRegionIds.push(key);
    } else {
      delete draft.init.selection[key];
    }
  }
  selectedRegionIds.sort((a, b) => Number(BigInt(a) - BigInt(b)));
  draft.init.selectedRegionIds = selectedRegionIds;
};
