import * as React from 'react';
import { Link } from 'gatsby';

import type { Region, Action } from './SupportBrowserRegionPage_service';

type Props = {
  store: Record<string, Region>,
  dispatch: React.Dispatch<Action>,
  selectedRegionIds: string[],
  onChangeQuery: (query: string) => void,
};

const SupportBrowserRegionPage_SelectedRegions: React.FC<Props> = ({
  store,
  dispatch,
  selectedRegionIds,
  onChangeQuery,
}) => {
  const hasSelectedRegions = selectedRegionIds.length > 0;

  const selectedRegions = React.useMemo(() => {
    return selectedRegionIds
      .map(id => store[id])
      .filter(Boolean)
      .sort((a, b) => a.fullName.localeCompare(b.fullName));
  }, [store, selectedRegionIds]);

  return (
    <div>
      {hasSelectedRegions && (
        <div>
          <label>
            선택 지역({selectedRegions.length}):
            <input
              type="text"
              readOnly
              value={selectedRegionIds.join(',')}
            />
          </label>
          <button
            onClick={() => dispatch({
              type: 'UNSELECT_ALL',
              ids: selectedRegionIds,
            })}
          >
            전체 삭제
          </button>
        </div>
      )}
      <ul>
        {selectedRegions.map(({
          slug,
          fullName,
          publicId,
        }) => {
          const [first, second, third, fourth] = fullName.split(' ');
          return (
            <li key={publicId}>
              <button
                onClick={() => dispatch({
                  type: 'UNSELECT',
                  id: publicId,
                })}
              >
                X
              </button>
              <span style={{
                display: 'inline-flex',
                gap: '0.2rem',
              }}>
                <a
                  href="#"
                  onClick={_ => {
                    onChangeQuery(first);
                  }}
                >
                  {first}
                </a>
                <a
                  href="#"
                  onClick={_ => {
                    onChangeQuery(`${first} ${second}`);
                  }}
                >
                  {second}
                </a>
                <a
                  href="#"
                  onClick={_ => {
                    onChangeQuery(`${first} ${second} ${third}`);
                  }}
                >
                  {third}
                </a>
                {fourth && <span>{fourth}</span>}
                (
                <Link to={`/kr/${slug}/partners/`}>
                  #{publicId}
                </Link>
                )
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default React.memo(SupportBrowserRegionPage_SelectedRegions);
